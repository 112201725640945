.block_main.content {}

.main {
	>*:last-child { margin-bottom: 0; }

	.table {
		tr > td:first-of-type {
			white-space: nowrap;
			padding-right: 20px;
		}
	}

}