.page_wrapper {
	background-color: rgba($white, 0.8);
	max-width: 1170px;
	margin: 0 auto;
}


.block {
	padding: 30px 15px;
}


.block_brands {
	padding: 30px 0 0;
	text-align: center;
	line-height: 0;

	.brands_wrapper {
		padding: 15px 0 30px;
		background-color: rgba(black,0.8);
	}

	svg {
		fill: $white;
		max-width: 100px;
		max-height: 100px;
		padding: 0 8px;
	}

	@include media-breakpoint-only(lg) {
		svg {
			max-width: 125px;
			max-height: 100px;
		}
	}

	@include media-breakpoint-up(xl) {
		.brands_wrapper { padding-bottom: 15px; }
		svg {
			max-width: 85px;
			max-height: 70px;
		}
	}

}