body {

	min-width: 360px;
	padding: 30px;
	//background-color: rgba($color-primary,0.8);
	background-image:  	linear-gradient(
							to bottom,
							rgba($color-primary, 0.5),
							rgba($color-primary, 0.5)
						),
						url("/_/images/layout/white_leather.webp");
								
	&:not(.page_front) {
			
		@include media-breakpoint-up(md) {

			height: 100%;
			padding-bottom: 48px;

			.site_info {
				position: absolute;
				bottom: 0;
				width: 100%;
			}

		}
		
	}


}

#directions,
#hours {
	outline: none !important;
	box-shadow: none !important;
}